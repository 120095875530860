// @all-no-translate
<template>
  <div class="Section0" style="layout-grid:15.6000pt;">
    <h1 align=center style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-pagination:widow-orphan;
text-align:center;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:MicrosoftYaHei-Bold;
mso-ansi-font-weight:bold;font-size:14.0000pt;mso-font-kerning:22.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="微软雅黑">YMS Platform Legal Declaration</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:MicrosoftYaHei-Bold;
mso-ansi-font-weight:bold;font-size:14.0000pt;mso-font-kerning:22.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);"></span></b></h1>
    <h1 align=center style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-pagination:widow-orphan;
text-align:center;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:MicrosoftYaHei-Bold;
mso-ansi-font-weight:normal;font-size:10.0000pt;mso-font-kerning:22.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="微软雅黑">Release date: November 3, 2021</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:MicrosoftYaHei-Bold;
mso-ansi-font-weight:normal;font-size:10.0000pt;mso-font-kerning:22.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);"></span></h1>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1、 Ownership of rights</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Unless otherwise stated by the YMS platform (referring to websites and client services that provide network and software services, the same below), all products, technologies, software, programs All rights (including copyright, trademark, patent, trade secrets, and other related rights) to data and other information (including text, icons, images, photos, audio, video, charts, color combinations, layout design, etc.) belong to the YMS platform service provider (referring to Shenzhen Yunmai Supply Chain Management Co., Ltd., the same below) and/or its affiliated companies.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">The words and/or logos such as "YMS" on the YMS platform, as well as other logos, badges, product and service names on the YMS platform, are trademarks of the YMS platform service provider and/or its affiliated companies in China and other countries. If there is any need for promotion, display, or other use, you must obtain prior written permission from the YMS platform service provider and/or its affiliated companies.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Words and/or logos, logos, product and service names that other YMS platform service providers and/or their affiliated companies do not claim rights may be trademarks of their respective owners. Without the consent of the corresponding owners, you should not be understood as having the right to use the aforementioned trademarks displayed on the YMS platform.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;</span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">2、 Information Restrictions</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">In order to maintain the normal operation order and efficiency of the YMS platform, without the permission of the YMS platform service provider and/or its affiliated companies, no one shall, on their own, authorize or assist a third party to scan and/or detect the address, network port, operating system, etc. of the content under the YMS platform and/or its affiliated companies (including all content under the yms.com domain name), or to monitor and/or detect it through programs or devices such as robots, spiders, etc Unauthorized acquisition and use of any content within the YMS platform through copying, dissemination, display, mirroring, uploading, downloading, etc.</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;</span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">3、 Limitation of Liability</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">Considering that the store and product information on the YMS platform (including but not limited to store name, company name, contact and contact information, product description and description, relevant images, videos, product quality commitment, delivery time guarantee, brand authorization, etc.) are provided and uploaded by users themselves, and users bear corresponding legal responsibility for the information provided and uploaded. We do not make any guarantees or commitments regarding this. If the YMS platform service provider has other agreements regarding this, they will clarify it with you in relevant agreements or other legal texts.</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">The works reprinted on the YMS platform are for the purpose of conveying more information, and it does not mean that we agree with their views or have confirmed the authenticity of their content.</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;</span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">4、 Intellectual Property Protection</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">We respect intellectual property rights, oppose and combat infringement of intellectual property rights. If the intellectual property rights holder believes that the content of the YMS platform (including but not limited to product information published by users of the YMS platform) infringes on their legitimate rights and interests, they can file a complaint through the YMS platform customer service (400-0600-405). We will promptly handle the complaint in accordance with the relevant laws, regulations, and YMS platform rules after receiving the qualified notice from the intellectual property rights holder.</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
  </div>
</template>

<script>

export default {
  name: "legalDeclarationUS",

}
</script>

<style lang="less" scoped>
@font-face {
  font-family: "Times New Roman";
}

@font-face {
  font-family: "宋体";
}

@font-face {
  font-family: "Wingdings";
}

@font-face {
  font-family: "Calibri";
}

@font-face {
  font-family: "微软雅黑";
}

@font-face {
  font-family: "MicrosoftYaHei-Bold";
}

p.MsoNormal {
  mso-style-name: 正文;
  mso-style-parent: "";
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  text-align: justify;
  text-justify: inter-ideograph;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

h1 {
  mso-style-name: "标题 1";
  mso-style-next: 正文;
  mso-margin-top-alt: auto;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  mso-outline-level: 1;
  font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  mso-ansi-font-weight: bold;
  font-size: 24.0000pt;
  mso-font-kerning: 22.0000pt;
}

h2 {
  mso-style-name: "标题 2";
  mso-style-next: 正文;
  mso-margin-top-alt: auto;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  mso-outline-level: 2;
  font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  mso-ansi-font-weight: bold;
  font-size: 18.0000pt;
}

span.10 {
  font-family: 'Times New Roman';
}

span.15 {
  font-family: 'Times New Roman';
  color: rgb(0, 0, 255);
  text-decoration: underline;
  text-underline: single;
}

span.16 {
  font-family: 'Times New Roman';
  font-size: 10.5000pt;
}

span.17 {
  font-family: Calibri;
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

span.18 {
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

span.19 {
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

span.20 {
  font-family: Calibri;
  font-weight: bold;
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoCommentText {
  mso-style-name: 批注文字;
  mso-style-noshow: yes;
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoCommentSubject {
  mso-style-name: 批注主题;
  mso-style-noshow: yes;
  mso-style-parent: 批注文字;
  mso-style-next: 批注文字;
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-weight: bold;
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoHeader {
  mso-style-name: 页眉;
  mso-style-noshow: yes;
  margin: 0pt;
  margin-bottom: .0001pt;
  border-bottom: 1.0000pt solid windowtext;
  mso-border-bottom-alt: 0.7500pt solid windowtext;
  padding: 0pt 0pt 1pt 0pt;
  layout-grid-mode: char;
  mso-pagination: none;
  text-align: center;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoFooter {
  mso-style-name: 页脚;
  mso-style-noshow: yes;
  margin: 0pt;
  margin-bottom: .0001pt;
  layout-grid-mode: char;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

p.p {
  mso-style-name: "普通\(网站\)";
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  text-align: justify;
  text-justify: inter-ideograph;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 12.0000pt;
  mso-font-kerning: 1.0000pt;
}

span.msoIns {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: underline;
  text-underline: single;
  color: blue;
}

span.msoDel {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: line-through;
  color: red;
}

table.MsoNormalTable {
  mso-style-name: 普通表格;
  mso-style-parent: "";
  mso-style-noshow: yes;
  mso-tstyle-rowband-size: 0;
  mso-tstyle-colband-size: 0;
  mso-padding-alt: 0.0000pt 5.4000pt 0.0000pt 5.4000pt;
  mso-para-margin: 0pt;
  mso-para-margin-bottom: .0001pt;
  mso-pagination: widow-orphan;
  font-family: 'Times New Roman';
  font-size: 10.0000pt;
  mso-ansi-language: #0400;
  mso-fareast-language: #0400;
  mso-bidi-language: #0400;
}

@page {
  mso-page-border-surround-header: no;
  mso-page-border-surround-footer: no;
}

@page Section0 {
  margin-top: 72.0000pt;
  margin-bottom: 72.0000pt;
  margin-left: 90.0000pt;
  margin-right: 90.0000pt;
  size: 595.3000pt 841.9000pt;
  layout-grid: 15.6000pt;
  mso-header-margin: 42.5500pt;
  mso-footer-margin: 49.6000pt;
}

div.Section0 {
  page: Section0;
}
</style>