// @all-no-translate
<template>
  <div class="Section0" style="layout-grid:15.6000pt;">
    <h1 align=center style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-pagination:widow-orphan;
text-align:center;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:MicrosoftYaHei-Bold;
mso-ansi-font-weight:bold;font-size:14.0000pt;mso-font-kerning:22.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="微软雅黑">YMS平台法律声明</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:MicrosoftYaHei-Bold;
mso-ansi-font-weight:bold;font-size:14.0000pt;mso-font-kerning:22.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);"></span></b></h1>
    <h1 align=center style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-pagination:widow-orphan;
text-align:center;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:MicrosoftYaHei-Bold;
mso-ansi-font-weight:normal;font-size:10.0000pt;mso-font-kerning:22.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="微软雅黑">发布日期：</font><font face="微软雅黑">2</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:MicrosoftYaHei-Bold;
mso-ansi-font-weight:normal;font-size:10.0000pt;mso-font-kerning:22.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">021</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:MicrosoftYaHei-Bold;
mso-ansi-font-weight:normal;font-size:10.0000pt;mso-font-kerning:22.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="微软雅黑">年</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:MicrosoftYaHei-Bold;
mso-ansi-font-weight:normal;font-size:10.0000pt;mso-font-kerning:22.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">11</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:MicrosoftYaHei-Bold;
mso-ansi-font-weight:normal;font-size:10.0000pt;mso-font-kerning:22.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="微软雅黑">月</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:MicrosoftYaHei-Bold;
mso-ansi-font-weight:normal;font-size:10.0000pt;mso-font-kerning:22.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">3</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:MicrosoftYaHei-Bold;
mso-ansi-font-weight:normal;font-size:10.0000pt;mso-font-kerning:22.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="微软雅黑">日</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:MicrosoftYaHei-Bold;
mso-ansi-font-weight:normal;font-size:10.0000pt;mso-font-kerning:22.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);"></span></h1>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">一、权利归属</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></b></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">除非</font><font face="微软雅黑">YMS平台（指提供网络及软件服务的网站及客户端服务，下同），另行声明，</font></span><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">YMS平台内的所有产品、技术、软件、程序、数据及其他信息（包括文字、图标、图片、照片、音频、视频、图表、色彩组合、版面设计等）的所有权利（包括版权、商标权、专利权、商业秘密及其他相关权利）均归YMS平台服务提供者（指深圳云卖供应链管理有限公司，下同）及/或其关联公司所有。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">平台的</font><font face="微软雅黑">“YMS”等文字及/或标识，以及YMS平台的其他标识、徽记、产品和服务名称均为YMS平台服务提供者及/或其关联公司在中国和其他国家的商标。如有宣传、展示等任何使用需要，请您务必取得YMS平台服务提供者及/或其关联公司的事先书面许可。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
color:rgb(0,0,0);font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">其他</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">YMS平台</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
color:rgb(0,0,0);font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">服务提供者及</font><font face="微软雅黑">/或其关联公司没有主张权利的文字及/或标识、徽记、产品和服务名称则可能是它们各自所有权人的商标，在未获得相应权利人同意的情况下，您不应理解为有权使用被展示于</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">YMS平台</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
color:rgb(0,0,0);font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">的前述商标。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-para-margin-top:1.0000gd;
mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;line-height:18.0000pt;
mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">二、信息限制</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></b></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">为维护</font><font face="微软雅黑">YMS平台的正常运行秩序及效率，未经YMS平台服务提供者及/或其关联公司许可，任何人不得自行、授权或协助第三方对YMS平台及/或其关联公司下的内容（包括</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">yms.com</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">域名下的所有内容）进行地址扫描、网络端口扫描、操作系统探测等扫描及</font><font face="微软雅黑">/或探测，或以包括通过机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载等方式擅自获取、使用YMS平台内的任何内容。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-para-margin-top:1.0000gd;
mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;line-height:18.0000pt;
mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">三、责任限制</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></b></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">鉴于</font><font face="微软雅黑">YMS平台上的店铺、商品信息（包括但不限于店铺名称、公司名称、联系人及联络信息、商品的描述和说明、相关图片、视频、商品质量承诺、发货时效保证、品牌授权等）由用户自行提供并上传，由用户对其提供并上传的信息承担相应法律责任，我们对此不作出任何保证或承诺。YMS平台服务提供者对此另有约定的，将在相关的协议或其他法律文本中与您进行明确。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">YMS平台转载的作品出于传递更多信息之目的，并不意味我们赞同其观点或已经证实其内容的真实性。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-para-margin-top:1.0000gd;
mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;line-height:18.0000pt;
mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">四、知识产权保护</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></b></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">我们尊重知识产权，反对并打击侵犯知识产权的行为。知识产权权利人若认为</font><font face="微软雅黑">YMS平台内容（包括但不限于YMS平台用户发布的商品信息）侵犯其合法权益的，可以通过YMS平台客服（</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">400-0600-405</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">）进行投诉，我们将在收到知识产权权利人合格通知后依据相应的法律法规以及</font><font face="微软雅黑">YMS平台规则及时处理。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <h1 style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-pagination:widow-orphan;
line-height:18.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:MicrosoftYaHei-Bold;
mso-ansi-font-weight:bold;font-size:13.5000pt;mso-font-kerning:22.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">&nbsp;</span></b></h1>
  </div>
</template>

<script>

export default {
  name: "legalDeclarationCN",
  data() {
    let v = this;
    return {}
  },
  created() {

  },
  methods: {}
}
</script>

<style lang="less" scoped>
@font-face {
  font-family: "Times New Roman";
}

@font-face {
  font-family: "宋体";
}

@font-face {
  font-family: "Wingdings";
}

@font-face {
  font-family: "Calibri";
}

@font-face {
  font-family: "微软雅黑";
}

@font-face {
  font-family: "MicrosoftYaHei-Bold";
}

p.MsoNormal {
  mso-style-name: 正文;
  mso-style-parent: "";
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  text-align: justify;
  text-justify: inter-ideograph;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

h1 {
  mso-style-name: "标题 1";
  mso-style-next: 正文;
  mso-margin-top-alt: auto;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  mso-outline-level: 1;
  font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  mso-ansi-font-weight: bold;
  font-size: 24.0000pt;
  mso-font-kerning: 22.0000pt;
}

h2 {
  mso-style-name: "标题 2";
  mso-style-next: 正文;
  mso-margin-top-alt: auto;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  mso-outline-level: 2;
  font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  mso-ansi-font-weight: bold;
  font-size: 18.0000pt;
}

span.10 {
  font-family: 'Times New Roman';
}

span.15 {
  font-family: Calibri;
  font-weight: bold;
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

span.16 {
  font-family: 'Times New Roman';
  color: rgb(0, 0, 255);
  text-decoration: underline;
  text-underline: single;
}

span.17 {
  font-family: 'Times New Roman';
  font-size: 10.5000pt;
}

span.18 {
  font-family: Calibri;
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

span.19 {
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

span.20 {
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

p.p {
  mso-style-name: "普通\(网站\)";
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  text-align: justify;
  text-justify: inter-ideograph;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 12.0000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoCommentText {
  mso-style-name: 批注文字;
  mso-style-noshow: yes;
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoFooter {
  mso-style-name: 页脚;
  mso-style-noshow: yes;
  margin: 0pt;
  margin-bottom: .0001pt;
  layout-grid-mode: char;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoHeader {
  mso-style-name: 页眉;
  mso-style-noshow: yes;
  margin: 0pt;
  margin-bottom: .0001pt;
  border-bottom: 1.0000pt solid windowtext;
  mso-border-bottom-alt: 0.7500pt solid windowtext;
  padding: 0pt 0pt 1pt 0pt;
  layout-grid-mode: char;
  mso-pagination: none;
  text-align: center;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoCommentSubject {
  mso-style-name: 批注主题;
  mso-style-noshow: yes;
  mso-style-parent: 批注文字;
  mso-style-next: 批注文字;
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-weight: bold;
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

span.msoIns {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: underline;
  text-underline: single;
  color: blue;
}

span.msoDel {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: line-through;
  color: red;
}

table.MsoNormalTable {
  mso-style-name: 普通表格;
  mso-style-parent: "";
  mso-style-noshow: yes;
  mso-tstyle-rowband-size: 0;
  mso-tstyle-colband-size: 0;
  mso-padding-alt: 0.0000pt 5.4000pt 0.0000pt 5.4000pt;
  mso-para-margin: 0pt;
  mso-para-margin-bottom: .0001pt;
  mso-pagination: widow-orphan;
  font-family: 'Times New Roman';
  font-size: 10.0000pt;
  mso-ansi-language: #0400;
  mso-fareast-language: #0400;
  mso-bidi-language: #0400;
}

@page {
  mso-page-border-surround-header: no;
  mso-page-border-surround-footer: no;
}

@page Section0 {
  margin-top: 72.0000pt;
  margin-bottom: 72.0000pt;
  margin-left: 90.0000pt;
  margin-right: 90.0000pt;
  size: 595.3000pt 841.9000pt;
  layout-grid: 15.6000pt;
  mso-header-margin: 42.5500pt;
  mso-footer-margin: 49.6000pt;
}

div.Section0 {
  page: Section0;
}
</style>